<template>
  <layout-centered>
    <div class="px-4 py-5">
      <img class="d-block mx-auto mb-4" src="@/assets/img/ifs-logo-335x56.png" :alt="appTitle" width="335">

      <p class="mb-4">Um Ihr Passwort zurückzusetzen, schreiben Sie bitte unser Support-Team unter der <a href="mailto:support@stina-global.com">support@stina-global.com</a> an.</p>

      <router-link class="btn btn-primary" :to="{ name: 'sign-in' }" >{{ $t("back") }}</router-link>

    </div>
  </layout-centered>
</template>

<script>
import LayoutCentered from "@/layouts/LayoutCentered";
export default {
  name: "PagePasswordReset",
  components: { LayoutCentered },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    }
  },
}
</script>

<style scoped>

</style>